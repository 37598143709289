import React, { useMemo, useState } from "react"
import tw, { css } from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import DrawerContent from "./drawer-content"
import AppIcon from "@atoms/icon"
import useBreakpoints from "../../../utils/use-breakpoints"
import DrawerSingleStory from "./drawer-single-story"
import DrawerToggle from "./drawer-toggle"
import { DRAWER_BASE_WIDTH, DRAWER_BREAKPOINTS } from "./drawer-config"

import { useMediaQuery } from "../../../utils/use-media-query"
import useDrawerData from "./use-drawer-data"

const EpisodesDrawer = () => {
  const { ft: data } = useStaticQuery(query)
  const { selectedStory, localisedData } = useDrawerData(data)
  const drawerWidth = useBreakpoints(DRAWER_BREAKPOINTS, DRAWER_BASE_WIDTH)
  const width = useMemo(() => {
    if (selectedStory) {
      return "220px"
    }
    return drawerWidth
  }, [drawerWidth, selectedStory])
  const [state, setState] = useState({
    open: false,
  })
  const setOpen = open => {
    setState({ ...state, open })
  }
  const toggle = () => setOpen(!state.open)
  const close = () => setOpen(false)
  const isXL = useMediaQuery("(min-width: 1024px)")

  if (selectedStory && isXL) {
    return null
  }

  return (
    <div
      css={[tw`fixed`]}
      style={{
        zIndex: state.open ? 130 : 100,
      }}
    >
      <AnimatePresence>
        {state.open && (
          <motion.div
            tw="fixed inset-0 w-full h-full bg-black bg-opacity-90"
            role="button"
            onClick={close}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          ></motion.div>
        )}
      </AnimatePresence>
      <motion.div
        className="tear-l-white"
        css={[
          tw`fixed top-0 bottom-0 bg-white`,
          css`
            width: ${width};
            right: 0;
            transform: translateX(100%);
          `,
        ]}
        variants={{ in: { x: 0 }, out: { x: "100%" } }}
        initial="out"
        animate={state.open ? "in" : "out"}
      >
        {selectedStory && !isXL ? (
          <DrawerSingleStory story={selectedStory} />
        ) : (
          <DrawerContent data={localisedData} />
        )}

        <DrawerToggle onClick={toggle} isOpen={state.open} />
        <button
          tw="absolute top-0 right-0 border-brand-black border-2 rounded-full p-1 m-4 focus:(outline-none text-brand-green)"
          onClick={close}
        >
          <AppIcon name="close" tw="w-5 h-5 block" />
        </button>
      </motion.div>
    </div>
  )
}

const query = graphql`
  fragment LocalisedDrawerData on FT_EpisodesDrawer {
    locale
    title
    text
    stories(sort: "order") {
      id
      name
      location
      episodes {
        locale
        id
        title
        formatted_release_date
        release_date
        description
      }
    }
  }

  fragment DrawerData on FT_EpisodesDrawer {
    stories(sort: "order") {
      id
      character_name
      character_thumbnail {
        url
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, width: 64, layout: CONSTRAINED)
          }
        }
      }
      episodes {
        ...EpisodeSummary
        locale
        id
        localizations {
          locale
          id
        }
      }
      locale
      id
      localizations {
        locale
        id
      }
    }
  }
  query Drawer {
    ft {
      common: episodesDrawer {
        ...DrawerData
      }
      en: episodesDrawer(locale: "en") {
        ...LocalisedDrawerData
      }
      es: episodesDrawer(locale: "es") {
        ...LocalisedDrawerData
      }
      fr: episodesDrawer(locale: "fr") {
        ...LocalisedDrawerData
      }
      de: episodesDrawer(locale: "de") {
        ...LocalisedDrawerData
      }
      it: episodesDrawer(locale: "it") {
        ...LocalisedDrawerData
      }
      pl: episodesDrawer(locale: "pl") {
        ...LocalisedDrawerData
      }
    }
  }
`

export default EpisodesDrawer
