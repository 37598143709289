import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import AppButton from "@atoms/button"
import AppIcon from "@atoms/icon"
import tw, { css } from "twin.macro"
import { injectIntl, FormattedMessage } from "react-intl"
import { getLocalization, prefixLink } from "../../i18n/helpers"
import Sandbox from "../atoms/sandbox"

const footerLight = css`
  background: white;
`
const footerDark = css`
  background: #1d1d1d;
  .app-footer-links,
  .app-footer-cta__text {
    color: #dadada;
  }
  .app-footer-cta__title {
    color: white;
  }
`
const Footer = ({ path, intl }) => (
  <StaticQuery
    query={graphql`
      {
        ft {
          privacy {
            slug
            name
          }
          getInvolved(publicationState: LIVE) {
            locale
            slug
            name
            localizations {
              name
              locale
              slug
            }
          }
          footer {
            title
            text
            action {
              id
              external
              icon_prefix
              icon_suffix
              name
              target
              theme
              url
            }
            icon
            localizations {
              locale
              title
              text
              action {
                id
                external
                icon_prefix
                icon_suffix
                name
                target
                theme
                url
              }
            }
          }
        }
      }
    `}
    render={({ ft: { footer, privacy, getInvolved } }) => {
      const localisedFooter = getLocalization(footer, intl.locale)
      const gi =
        intl.locale !== "en" &&
        !getInvolved.localizations.some(x => x.locale === intl.locale)
          ? null
          : getLocalization(getInvolved, intl.locale)
      const isLight =
        path.split("/").join("") === (intl.locale === "en" ? "" : intl.locale)
      return (
        <footer
          className={`${isLight ? "tear-t-lg-white" : "tear-t-lg-dark"}`}
          tw="relative"
          css={[isLight ? footerLight : footerDark]}
        >
          <div tw="max-w-screen-xl mx-auto px-6 pt-8 pb-12 text-center xl:(pt-12 px-12)">
            <div tw="items-center justify-between lg:flex">
              <div tw="items-start sm:flex">
                {footer.icon && (
                  <AppIcon
                    name={footer.icon}
                    tw="w-24 h-24 text-brand-blue block mx-auto flex-shrink-0 xl:(w-28 h-28)"
                  />
                )}
                <div tw=" sm:text-left sm:pl-4">
                  {localisedFooter.title && (
                    <h3
                      className="app-footer-cta__title"
                      tw="text-4xl font-display font-bold"
                    >
                      {localisedFooter.title}
                    </h3>
                  )}
                  <Sandbox
                    className="app-footer-cta__text"
                    tw="mt-2"
                    dangerouslySetInnerHTML={{ __html: localisedFooter.text }}
                  />
                  {gi && (
                    <Link
                      css={[
                        tw`uppercase`,
                        isLight ? tw`text-brand-blue` : tw`text-brand-green`,
                      ]}
                      to={prefixLink(`/${gi.slug}`, intl.locale)}
                      title={gi.name}
                      activeStyle={{ visibility: "hidden" }}
                    >
                      <FormattedMessage
                        id="find_out_more"
                        defaultMessage="Find out more"
                      />
                    </Link>
                  )}
                </div>
              </div>
              <div tw="leading-none flex flex-wrap items-center justify-center sm:ml-24 sm:justify-start lg:flex-grow xl:flex-nowrap">
                {Array.isArray(localisedFooter.action) &&
                  localisedFooter.action.map(action => (
                    <AppButton
                      key={action.id}
                      Tag={action.url ? "a" : "button"}
                      tw="my-2 mx-4 xl:my-0"
                      suffix={action.icon_suffix}
                      prefix={action.icon_prefix}
                      theme={action.theme}
                      href={action.url}
                      target={action.target === "BLANK" ? "_BLANK" : ""}
                    >
                      {action.name}
                    </AppButton>
                  ))}
              </div>
            </div>
            <div
              className="app-footer-links"
              tw="mt-8 text-sm items-center sm:flex sm:ml-28 sm:mt-12"
            >
              <p>© Fairtrade International {new Date().getFullYear()}</p>
              {privacy && privacy.slug && (
                <Link
                  key="item.id"
                  tw="mt-4 block sm:mt-0 sm:ml-12"
                  to={`/${privacy.slug}`}
                >
                  {privacy.name}
                </Link>
              )}
            </div>
          </div>
        </footer>
      )
    }}
  ></StaticQuery>
)

export default injectIntl(Footer)
