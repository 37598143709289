import { navigate } from "gatsby"
import { useEffect } from "react"
import { prefersLangToken } from "./config"
import { getRedirectLanguage } from "./helpers"
export const useLocaleRedirect = function (localisedSlugs, currentLocale) {
  useEffect(() => {
    if (
      typeof localStorage !== "undefined" &&
      typeof localStorage.getItem === "function"
    ) {
      const locale =
        localStorage.getItem(prefersLangToken) || getRedirectLanguage()
      if (locale !== currentLocale) {
        const localSlug = localisedSlugs.find(x => x.locale === locale)
        if (localSlug) {
          navigate(localSlug.to, { replace: true })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
