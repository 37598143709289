module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-9801943-1","cookieName":"farmers-gdpr-gtag","anonymize":true,"allowAdFeatures":false},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/mp/Dev/fairtrade/farmers/farmers-client/src/layouts/layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"farmers-fight-the-global-crisis","short_name":"farmers","start_url":"/","background_color":"#1d1d1d","theme_color":"#0FC0FC","display":"minimal-ui","icon":"src/assets/fairtrade-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1cb8bd2f77df04fd482b3155d21e0188"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
