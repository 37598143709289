import { useContext, useMemo } from "react"
import { GlobalStateContext } from "../../../utils/global-context"
import { useIntl } from "react-intl"
import _pick from "lodash/pick"
const getById = (collection, id) => {
  return collection.find(item => item.id === id)
}
const getLocalisedId = (data, locale) => {
  if (locale === "en") return data.id
  return data.localizations.find(x => x.locale === locale)?.id
}
const useDrawerData = data => {
  const intl = useIntl()
  const globalState = useContext(GlobalStateContext)
  const localisedData = useMemo(() => {
    const loc = data[intl.locale] || data.en
    return {
      ...data.common,
      ..._pick(loc, ["locale", "title", "text", "id"]),
      stories: data.common.stories.map(story => {
        let localisedStory = getById(
          loc.stories,
          getLocalisedId(story, intl.locale)
        )
        if (!localisedStory) return story
        return {
          ...story,
          ..._pick(localisedStory, ["name", "location", "id"]),
          episodes: story.episodes.map(episode => {
            const localisedEpisode = getById(
              localisedStory.episodes,
              getLocalisedId(episode, intl.locale)
            )
            if (!localisedEpisode) return episode
            return {
              ...episode,
              ..._pick(localisedEpisode, [
                "id",
                "title",
                "formatted_release_date",
                "description",
              ]),
            }
          }),
        }
      }),
    }
  }, [data, intl.locale])
  const selectedStory = useMemo(
    () =>
      localisedData.stories.find(
        story => story.id === globalState.selectedStory
      ),
    [localisedData, globalState.selectedStory]
  )

  return { selectedStory, localisedData }
}

export default useDrawerData
