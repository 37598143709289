import React, { useMemo, useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { useIntl } from "react-intl"
import { motion, AnimatePresence } from "framer-motion"
import { locales, prefersLangToken } from "../../../i18n/config"
import { prefixLink } from "../../../i18n/helpers"
import AppIcon from "@atoms/icon"
import Helmet from "react-helmet"
import tw from "twin.macro"
import { useLocaleRedirect } from "../../../i18n/use-locale-redirect"

const flattenLocalizationTree = data => {
  if (!data) return null
  return {
    en: {
      slug: data.slug,
      type: data.__typename,
    },
    ...data.localizations.reduce((memo, v) => {
      memo[v.locale] = {
        slug: v.slug,
        type: data.__typename,
      }
      return memo
    }, {}),
  }
}
const getPath = (locale, leaf) => {
  if (!leaf || !leaf.slug) return prefixLink("/", locale)
  if (leaf.type === "FT_Story" && locale === "en") return `/story/${leaf.slug}`
  return prefixLink(`/${leaf.slug}`, locale)
}
const LocaleSwitch = ({ path }) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const { ft: data } = useStaticQuery(query)
  const flatData = ["about", "meetTheFilmaker", "getInvolved"]
    .map(key => flattenLocalizationTree(data[key]))
    .filter(x => !!x)
    .concat(data.stories.map(flattenLocalizationTree))

  const localisedSlugs = useMemo(() => {
    const lastSegment = path.replace(/\/$/, "").split("/").pop()
    const tree = flatData.find(
      branch => branch[intl.locale]?.slug === lastSegment
    )
    return locales
      .filter(x => x !== intl.locale)
      .map(locale => ({
        locale,
        to: getPath(locale, tree && tree[locale] ? tree[locale] : null),
      }))
  }, [path, flatData, intl.locale])

  const handleNavigation = (event, { locale, to }) => {
    if (typeof event?.preventDefault === "function") {
      event.preventDefault()
      localStorage.setItem(prefersLangToken, locale)
      navigate(to)
    }
  }

  useLocaleRedirect(localisedSlugs, intl.locale)

  return (
    <>
      <Helmet>
        {localisedSlugs.map(x => (
          <link
            key={x.locale}
            rel="alternate"
            hrefLang={x.locale}
            href={`${process.env.GATSBY_SITE_URL}${x.to}`}
          />
        ))}
      </Helmet>
      <div tw="relative text-white mr-4">
        <button
          onClick={() => setShow(!show)}
          tw="uppercase text-brand-green inline-flex items-center focus:outline-none"
        >
          <span>{intl.locale}</span>
          <AppIcon
            name="arrow-right"
            css={[
              tw` transition-transform transform block text-white w-3 h-3 ml-2`,
              (show && tw`-rotate-90`) || tw`rotate-90`,
            ]}
          />
        </button>
        <AnimatePresence>
          {show && (
            <motion.div
              tw="absolute top-full left-0 -ml-6 py-4 px-6 bg-brand-dark"
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "100%", opacity: 0 }}
            >
              <ul>
                {localisedSlugs.map(x => (
                  <li key={x.locale}>
                    <a
                      href={x.to || ""}
                      tw="uppercase block pb-1 hover:text-brand-green"
                      onClick={event => handleNavigation(event, x)}
                    >
                      {x.locale}
                    </a>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

export const query = graphql`
  query Sitemap {
    ft {
      stories {
        __typename
        locale
        localizations {
          id
          locale
          slug
          name
        }
        slug
        name
      }
      about {
        __typename
        localizations {
          slug
          locale
          name
        }
        locale
        slug
        name
      }
      meetTheFilmaker {
        __typename
        localizations {
          slug
          name
          id
          locale
        }
        id
        locale
        name
        slug
      }
      getInvolved {
        __typename
        localizations {
          slug
          name
          id
          locale
        }
        id
        locale
        name
        slug
      }
    }
  }
`

export default LocaleSwitch
