import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import tw, { styled } from "twin.macro"
import EpisodeThumb from "./episode-thumb"

const CharacterPortrait = styled(GatsbyImage)`
  ${tw`!overflow-hidden !rounded-full flex-shrink-0 w-14 h-14 lg:(w-16 h-16)`}
  img {
    ${tw`rounded-full`}
  }
`

const DrawerStory = ({ story }) => {
  const thumbnail = getImage(story.character_thumbnail.localFile)
  return (
    <>
      <div tw="flex items-center py-4">
        <CharacterPortrait
          image={thumbnail}
          alt={story.character_thumbnail.alternativeText}
        />
        <div tw="pl-2">
          <p tw="text-brand-blue leading-none">{story.location}</p>
          <p tw="font-display font-black text-brand-black text-3xl leading-none uppercase md:text-xl xl:text-3xl">
            {story.character_name}
          </p>
        </div>
      </div>
      {story.episodes.map(episode => (
        <EpisodeThumb episode={episode} key={episode.id} />
      ))}
    </>
  )
}

export default DrawerStory
