import "twin.macro"
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import cookies from "js-cookie"
import { injectIntl, FormattedMessage } from "react-intl"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { Link } from "gatsby"
import AppIcon from "@atoms/icon"
const COOKIE_NAME = "farmers-gdpr-gtag"
const CookieBanner = () => {
  const [state, setState] = useState({ visible: false })
  useEffect(() => {
    const cookieValue = cookies.get(COOKIE_NAME)
    setState({ visible: !cookieValue })
  }, [])
  const location = useLocation()
  const accept = accepted => {
    setState({ visible: false, accepted })
    cookies.set(COOKIE_NAME, accepted)
    if (accepted) {
      initializeAndTrack(location)
    }
  }
  return (
    <div tw="fixed bottom-0 right-0 p-4 z-50">
      <AnimatePresence>
        {state.visible && (
          <motion.div
            tw="px-6 pt-6 pb-4 bg-white text-brand-black bg-opacity-90 rounded-md shadow-xl relative max-w-md"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
          >
            <p tw="text-xs lg:text-sm">
              <FormattedMessage
                id="gdpr_consent.message"
                defaultMessage="This website stores data such as cookies to enable important site
              functionality including analytics. Read our privacy policy"
              />{" "}
              <Link to="/privacy">
                <FormattedMessage
                  id="gdpr_consent.read"
                  defaultMessage="Read our privacy policy"
                />
              </Link>
            </p>
            <div tw="flex items-center justify-end mt-4 text-sm">
              <button onClick={() => accept(false)}>
                <FormattedMessage
                  id="gdpr_consent.decline"
                  defaultMessage="Decline"
                />
              </button>
              <button tw="ml-4" onClick={() => accept(true)}>
                <FormattedMessage
                  id="gdpr_consent.accept"
                  defaultMessage="Accept"
                />
              </button>
            </div>
            <button
              onClick={() => setState({ visible: false })}
              tw="absolute top-0 right-0 p-1"
            >
              <AppIcon name="close" tw="w-5 h-5 block" />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default injectIntl(CookieBanner)
