import React, { Suspense, useContext, useState } from "react"
import tw, { css } from "twin.macro"
import Portal from "@atoms/portal"
import CloseButton from "@atoms/close-button"
import { motion } from "framer-motion"
import useBodySrollLock from "../../utils/use-body-scroll-lock"
import {
  ACTIONS,
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../utils/global-context"
import { graphql } from "gatsby"

const VideoPlayer = React.lazy(() => import("../atoms/video-player"))

const FallbackComponent = () => <div>Fallback</div>

const VideoOverlay = ({ onClose, skipScrollLockClear }) => {
  const [target, setTarget] = useState()
  const state = useContext(GlobalStateContext)
  useBodySrollLock(target, skipScrollLockClear)
  return (
    <Portal>
      <motion.div
        tw="fixed inset-0 w-full h-full flex items-center overflow-hidden"
        css={css`
          z-index: 250;
        `}
        initial="out"
        animate="in"
        ref={setTarget}
      >
        <motion.div
          role="button"
          tw="absolute inset-0"
          css={css`
            background-color: rgba(0, 0, 0, 0.97);
          `}
          variants={{ out: { opacity: 0 }, in: { opacity: 1 } }}
          tabIndex="-1"
          onClick={onClose}
        ></motion.div>
        <motion.div
          tw="absolute top-0 left-0 right-0 bottom-5 xl:bottom-10"
          css={css`
            --plyr-color-main: #d4ff47;
            --plyr-font-size-large: 1.5rem;
            --plyr-video-control-background-hover: #1e1e1e;
          `}
          variants={{ out: { y: "-100%" }, in: { y: 0 } }}
        >
          <Suspense fallback={<FallbackComponent />}>
            <VideoPlayer video={state.video} />
          </Suspense>
        </motion.div>
        <CloseButton
          onClick={onClose}
          css={[
            tw`absolute top-0 right-0 xl:(right-20) xxl:right-40`,
            css`
              z-index: 9999;
            `,
          ]}
        />
      </motion.div>
    </Portal>
  )
}

const VideoOutlet = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const close = () => dispatch({ type: ACTIONS.setVideo, payload: null })
  // const intl = useIntl()
  return state.video && <VideoOverlay video={state.video} onClose={close} />
}

export const videoDataQuery = graphql`
  fragment VideoData on VimeoVideo {
    id
    width
    height
    files {
      link
      height
      size
      type
      width
      quality
      public_name
    }
  }
`

export default VideoOutlet
