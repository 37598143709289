import React from "react"
import "twin.macro"
import { FormattedMessage } from "react-intl"
import EpisodeThumb from "./episode-thumb"

const DrawerSingleStory = ({ story }) => (
  <div tw="p-6 overflow-y-auto h-full">
    <span tw="text-brand-blue text-sm">
      <FormattedMessage id="episodes" defaultMessage="Episodes" />
    </span>
    <h2 tw="font-display text-2xl uppercase pl-2 leading-none border-l border-brand-green mb-4">
      {story.name}
    </h2>
    {story.episodes.map(episode => (
      <EpisodeThumb key={episode.id} episode={episode} />
    ))}
  </div>
)

export default DrawerSingleStory
