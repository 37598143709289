import React from "react"
import PropTypes from "prop-types"
import Header from "@organisms/header/header"
import Footer from "@organisms/footer"
import CookieBanner from "@atoms/cookie-banner"
import EpisodesDrawer from "@organisms/episodes-drawer/drawer"
import { AnimatePresence, motion } from "framer-motion"
import { IntlProvider } from "react-intl"
import { getCurrentLocale } from "../i18n/helpers"
import GlobalContextProvider from "../utils/global-context"
import VideoOverlay from "../components/molecules/video-overlay"

const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}

const Layout = ({ location, children }) => {
  const url = (location && location.pathname) || ""
  const locale = getCurrentLocale(url)
  const messages = require(`../i18n/${locale}.json`)
  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <GlobalContextProvider>
        <Header path={url} />
        <AnimatePresence>
          <motion.main
            key={url}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {children}
          </motion.main>
        </AnimatePresence>
        <Footer path={url} />
        <EpisodesDrawer />
        <VideoOverlay />
        <CookieBanner />
      </GlobalContextProvider>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
